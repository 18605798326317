<script>
  import { get, sync, call } from 'vuex-pathify'
  import clone from 'clone-deep'
  import AddressForm from '@/components/account/AddressForm'
  import BillingCreditCardForm from '@/components/account/BillingCreditCardForm'
  import LoginForm from '@/components/account/LoginForm'
  import PayPalButton from '@/components/subscribe/PayPalButton'
  import ExistingMemberModal from '@/components/subscribe/ExistingMemberModal'
  import LogoPayPal from '@/images/logos/LogoPayPal.svg'
  import { getCreditCardToken, getPayPalToken } from '@/helpers/recurly'
  import { PLAN_PRICE } from '@/helpers/constants'
  import screen from '@/helpers/screen'
  import { events } from '@/helpers/gtm'
  import SelectPlanCard from '@/components/subscribe/SelectPlanCard'

  export default {
    components: {
      AddressForm,
      BillingCreditCardForm,
      LogoPayPal,
      PayPalButton,
      LoginForm,
      ExistingMemberModal,
      SelectPlanCard,
    },
    data() {
      return {
        addressFormRef: null,
        billingFormRef: null,
        accountFormRef: null,
        billingSameAsShipping: false,
        selectedPaymentMethodHandle: 'credit-card',
        calculatingPlanCost: false,
        calculatingShipping: false,
        calculatingDiscount: false,
        calculatingCredit: false,
        success: false,
        checkoutErrorMessage: null,
        recurlyPayload: null,
        showExistingMemberModal: false,
        PLAN_PRICE,
      }
    },
    computed: {
      screen,
      ...get('account', ['loggedIn', 'addresses', 'billingInfo', 'rememberedCredentials']),
      ...get('config', ['currentSeason', 'currentBox', 'isCurrentBoxWaitlist']),
      ...get('feature', ['flags']),
      ...get('subscribe', [
        'selectedPlan',
        'selectedPlanHandle',
        'selectedNewShippingAddress',
        'subscriptionPreview',
        'refreshingSubscriptionPreview',
        'useExistingBillingInfo',
        'hasExistingBillingInfo',
        'waitlistSecondsLeft',
      ]),
      ...get('couponAndGiftCard', ['submittedCouponCodeIsValid', 'submittedGiftCardIsValid']),
      ...get('prismic', ['getOrderSummaryData']),
      ...sync('subscribe', ['selectedShippingAddressId']),
      /* Dynamically changeable based on BaseRadio values **/
      paymentMethod: {
        get() {
          return this.selectedPaymentMethodHandle
        },
        set(value) {
          this.selectedPaymentMethodHandle = value
        },
      },
      orderSummaryCopy() {
        return this.getOrderSummaryData('subscribeFlow')
      },
      billingIsCreditCard() {
        return this.billingInfo?.type === 'credit-card'
      },
      payPalSelected() {
        return this.selectedPaymentMethodHandle === 'paypal'
      },
      priceBreakdownErrorMessage() {
        return (
          this.checkoutErrorMessage ??
          'We are unable to calculate the cost of your Membership. Please try selecting another shipping address.'
        )
      },
      calculatingTaxAndTotal() {
        return (
          this.calculatingPlanCost ||
          this.calculatingDiscount ||
          this.calculatingCredit ||
          this.calculatingShipping
        )
      },
      /* Disabling the checkout button if subscription preview is not loading / there's an error **/
      checkoutButtonDisabled() {
        return this.refreshingSubscriptionPreview || Boolean(this.checkoutErrorMessage)
      },
      selectedPlanLabel() {
        return this.selectedPlan?.label
      },
      boxName() {
        const introOrNot = this.flags['intro-box'] ? '' : '' // No special name for BF promo
        return `${this.currentSeason}${introOrNot}`
      },
      membershipPrice() {
        if (this.selectedPlanLabel === 'Alltrue+') {
          return PLAN_PRICE.alltruePlus
        } else if (this.selectedPlanLabel === 'Annual') {
          return PLAN_PRICE.annually
        }
        return PLAN_PRICE.quarterly // quarterly
      },
      isNewSubscribeDesignEnabled() {
        return this.flags['new-subscribe-design'] ?? false
      },
      isPayPalEnabled() {
        return this.flags['checkout-paypal'] ?? false
      },
      shippingTimeline() {
        return this.currentBox?.boxShippingTimeline
      },
    },
    watch: {
      refreshingSubscriptionPreview() {
        if (this.refreshingSubscriptionPreview) {
          this.calculatingPlanCost = true
          this.calculatingShipping = true
          this.calculatingDiscount = true
          this.calculatingCredit = true
        } else {
          this.calculatingPlanCost = false
          this.calculatingShipping = false
          this.calculatingDiscount = false
          this.calculatingCredit = false
        }
      },
    },
    created() {
      this.loadOrderSummaryData()
    },
    /* For new accounts, preview endpoint gets called once shipping form is filled out so we're making a call to preview if user already has billing data  **/
    async mounted() {
      if (!this.selectedPlan) {
        this.$navigate({ name: 'SubscribePlan' })
      } else {
        await this.resetSubscriptionPreview() // always reset to ensure preview data isn't stale
        if (this.hasExistingBillingInfo) {
          await this.refreshPreview()
        }
      }
    },
    destroyed() {
      if (this.success) {
        this.$store.set('subscribe/reset', {
          exclude: ['selectedShippingAddressId', 'subscriptionCreated', 'selectedPlanHandle'],
        })
      }
    },
    methods: {
      ...call('subscribe', [
        'refreshSubscriptionPreview',
        'createSubscription',
        'resetSubscriptionPreview',
      ]),
      ...call('prismic', ['loadOrderSummaryData']),
      htmlSerializer(type, element, text, children) {
        if (text) {
          text = text.replace(
            '{{ billing_cycle }}',
            this.selectedPlanLabel === 'Quarterly' ? 'a quarterly' : 'an annual'
          )
          return text
        }
      },
      priceBreakdown(lineItem) {
        if (!this.subscriptionPreview) {
          return undefined
        }
        return this.subscriptionPreview[lineItem] ?? 0
      },
      /* Get recurly token (happens when user clicks checkout with PayPal) **/
      async selectPayPal() {
        this.$store.set('general/navigationLoading', true)
        const token = await getPayPalToken()
        this.$store.set('general/navigationLoading', false)
        if (!token) {
          return
        }
        this.$store.set('subscribe/recurlyToken', token)
        this.startMembership()
      },
      savePaymentInfo(payload) {
        this.recurlyPayload = payload
        events.log({
          name: 'subscribe added payment info',
          data: { paymentMethod: this.billingInfo?.type },
        })

        // This new payment info should be used
        this.$store.set('subscribe/useExistingBillingInfoIfAny', false)
      },
      errorHandler(error) {
        if (error.data?.errors?.username?.includes('has already been taken')) {
          this.checkoutErrorMessage =
            'This email address is already associated with an existing account.'
          if (this.isCurrentBoxWaitlist) {
            this.showExistingMemberModal = true
          }
        } else if (error.data?.errors?.password) {
          this.checkoutErrorMessage = 'Invalid password'
        } else if (error.data?.errors?.shippingAddress) {
          this.checkoutErrorMessage =
            "Oops, there appears to be an error with your shipping address. If you're using a form autofill tool, try entering each field manually."
        } else {
          this.checkoutErrorMessage = error.message
        }
      },
      async clearErrors() {
        const address = this.addressFormRef?.address
        if (address?.firstName && address?.firstName !== address?.address1) {
          this.checkoutErrorMessage = null
        }

        //
        // Enable submit button again after card declined + new number entered
        //
        if (this.billingFormRef?.hasValidationErrors <= 0) {
          this.checkoutErrorMessage = null
        }
      },
      logAddedAccountInfo() {
        events.log({ name: 'subscribe added account info' })
      },
      async refreshPreview() {
        try {
          this.clearErrors()
          if (!this.selectedPlan) {
            this.$navigate({ name: 'SubscribePlan' })
          } else {
            await this.refreshSubscriptionPreview()
          }
        } catch (error) {
          this.checkoutErrorMessage = error.message
        }
      },
      async preview(address) {
        if (this.selectedNewShippingAddress) {
          if (address.firstName === address.address1) {
            this.checkoutErrorMessage = 'Address and name cannot have the same value.'
          }
          this.$store.set('subscribe/newShippingAddress', clone(address))
          await this.refreshPreview()
        }
        events.log({ name: 'subscribe added shipping' })
      },
      async startMembership() {
        try {
          this.checkoutErrorMessage = null

          //
          // Login validation
          //
          let accountValidated = true
          if (!this.loggedIn) {
            accountValidated = await this.accountFormRef?.formRef.validate()
          }

          //
          // Billing validation
          //
          const loggedInNewCreditCard =
            accountValidated &&
            this.selectedPaymentMethodHandle === 'credit-card' &&
            !this.useExistingBillingInfo
          const creditCardSelectedAndPaypalIsDefault =
            !this.hasExistingBillingInfo ||
            (this.hasExistingBillingInfo && !this.billingIsCreditCard)
          let billingValidated = true
          /* if credit card is selected as payment method &&
          they're not logged in or they're logged in but have paypal as default payment method,
          we need to validate the billing form **/
          if (
            (!this.payPalSelected && creditCardSelectedAndPaypalIsDefault) ||
            loggedInNewCreditCard
          ) {
            // Ensure new card number is saved if changed
            billingValidated = await this.billingFormRef?.processForm()
          }

          //
          // Address validation
          //
          const addressValidated = await this.addressFormRef?.formRef.validate()
          if (accountValidated && billingValidated && addressValidated) {
            this.$store.set('general/navigationLoading', true)
            if (
              this.selectedPaymentMethodHandle === 'credit-card' &&
              !this.useExistingBillingInfo
            ) {
              const token = await getCreditCardToken(this.recurlyPayload)
              this.$store.set('subscribe/recurlyToken', token)
            }
            // if user is selecting a new payment method from their current default, we need to send a new token
            if (
              this.billingInfo?.type !== this.selectedPaymentMethodHandle ||
              loggedInNewCreditCard
            ) {
              this.$store.set('subscribe/useExistingBillingInfoIfAny', false)
            }
            const subscription = await this.createSubscription()
            this.success = true
            this.$navigate({
              name: 'SubscribeInterstitial',
              params: {
                type: 'welcome',
                subscriptionId: subscription.id,
                subscribe: 'subscribe',
              },
            })
          } else {
            this.checkoutErrorMessage = 'Please fill out the required fields.'
            return
          }
        } catch (error) {
          this.errorHandler(error)
        } finally {
          this.$store.set('general/navigationLoading', false)
        }
      },
    },
  }
</script>

<template>
  <div>
    <BaseImage
      v-if="isCurrentBoxWaitlist"
      :src="
        screen.lg
          ? '/static/subscribe/waitlist_signup_desktop.jpg'
          : '/static/subscribe/waitlist_signup_mobile.jpg'
      "
      alt=""
    />

    <component :is="screen.lg ? 'BaseContainer' : 'div'" max-width="xl" class="pb-14 lg:pb-40">
      <BaseCountdownPill
        v-if="isCurrentBoxWaitlist"
        :seconds-left="waitlistSecondsLeft"
        class="-mt-9"
      />
      <div
        v-if="!isNewSubscribeDesignEnabled"
        :class="`lg:my-8 ${
          isCurrentBoxWaitlist
            ? 'px-4 my-8'
            : 'px-7 py-5 bg-primary text-dawn lg:bg-dawn lg:text-black'
        }`"
      >
        <h2 class="font-heading text-4xl lg:text-5xl text-center leading-none">
          <span
            v-if="isCurrentBoxWaitlist"
            class="font-bold text-2xl md:text-5xl"
            style="line-height: 0.89"
          >
            YOU’RE ALMOST DONE
          </span>
          <span v-else>Reserve your box and start customizing</span>
        </h2>
        <p
          :class="`mt-3 text-xs lg:text-base text-center leading-snug-tight ${
            isCurrentBoxWaitlist ? 'max-w-84 lg:max-w-110 mx-auto font-medium' : ''
          }`"
        >
          <span v-if="isCurrentBoxWaitlist">
            Join our Waitlist Reserve for $1 — to guarantee your spot on the list. We’ll also give
            you $5 off your first box! <br v-if="screen.lg" />
            Deposit refundable, cancel anytime.
            <BaseTooltip color="dawn-lt4" interactive class="align-middle inline-block -mt-1">
              <BaseIcon :size="4">
                <IconInfoAlertOutline />
              </BaseIcon>
              <template v-slot:tooltip>
                You can remove yourself from the waitlist and receive a refund for your $1.00
                waitlist deposit anytime, just email us at
                <BaseLinkStyled color="black" href="mailto:help@alltrue.com">
                  help@alltrue.com </BaseLinkStyled
                >.
              </template>
            </BaseTooltip>
          </span>
          <span v-else> Limited quantities available. Cancel anytime. Free shipping.*</span>
        </p>
      </div>
      <SelectPlanCard
        v-else-if="isNewSubscribeDesignEnabled && !screen.lg"
        :type="selectedPlanHandle"
        is-for-signup-view
        class="mt-5 mb-4 px-3"
      />
      <BaseDivider v-if="!screen.lg" color="black" :weight="2" class="w-full" />
      <div class="mt-6 mx-4 lg:mx-0 flex flex-col lg:flex-row lg:justify-center">
        <div class="w-full">
          <!-- Shipping form -->
          <div class="px-4 flex flex-col">
            <div class="flex flex-col items-center lg:items-start mb-6 lg:mb-2">
              <h3 class="font-semibold text-base lg:text-lg leading-close">Shipping</h3>
              <p class="mt-1 text-sm">Tell us where you’d like your box shipped.</p>
            </div>
            <AddressForm
              v-ref="addressFormRef"
              hide-button
              :select-option="Boolean(addresses.length)"
              @focus="clearErrors"
              @blurAddressInput="preview"
            />
            <div class="flex justify-center lg:justify-start">
              <BaseCheckbox
                v-model="billingSameAsShipping"
                vertical-alignment="center"
                class="mt-6"
              >
                <span class="font-semibold text-xs">Billing address: Same as shipping</span>
              </BaseCheckbox>
            </div>
            <BaseDivider
              color="black"
              :weight="screen.lg ? 1 : 2"
              :class="`${
                screen.lg ? 'w-full' : 'w-screen -ml-8'
              } mt-6 lg:mt-18px mb-18px lg:mb-26px`"
            />
          </div>

          <!-- Payment form -->
          <div class="px-4 flex flex-col">
            <div class="mb-5 text-center lg:text-left">
              <h3 class="font-semibold text-base lg:text-lg leading-close">Payment</h3>
            </div>
            <BaseRadioGroup
              v-model="paymentMethod"
              class="mb-5 flex flex-col lg:flex-row-reverse lg:justify-end"
            >
              <BaseRadio
                v-if="isPayPalEnabled"
                value="paypal"
                size="md"
                :vertical-alignment="`${screen.lg ? 'center' : 'top'}`"
                :class="`lg:w-1/2 lg:pb-4 ${
                  payPalSelected
                    ? 'lg:border-b-2 lg:border-primary'
                    : 'lg:border-b lg:border-gray-opacity68'
                } `"
              >
                <LogoPayPal class="w-30 h-8" />
              </BaseRadio>
              <div v-if="screen.lg" class="ml-2" />
              <BaseDivider v-if="!screen.lg" class="my-4" />
              <BaseRadio
                value="credit-card"
                :class="`lg:w-1/2 lg:pb-4 ${
                  payPalSelected
                    ? 'lg:border-b lg:border-gray-opacity68'
                    : 'lg:border-b-2 lg:border-primary'
                } `"
                :vertical-alignment="`${screen.lg ? 'center' : 'top'}`"
                :button-hidden="!isPayPalEnabled"
              >
                <span class="font-semibold"> Credit or Debit Card </span>
                <span class="mt-2 block">
                  <BaseCreditCards />
                </span>
              </BaseRadio>
            </BaseRadioGroup>
            <BillingCreditCardForm
              v-ref="billingFormRef"
              hide-button
              :billing-same-as-shipping="billingSameAsShipping"
              :selected-payment-method="selectedPaymentMethodHandle"
              show-discount-fields
              :discount-fields-disabled="Boolean(!subscriptionPreview)"
              :select-option="billingIsCreditCard"
              @focus="clearErrors"
              @blur="clearErrors"
              @submit="savePaymentInfo"
            />
            <BaseDivider
              color="black"
              :weight="screen.lg ? 1 : 2"
              :class="`${
                screen.lg ? 'w-full' : 'w-screen -ml-8'
              } mt-6 lg:mt-18px mb-18px lg:mb-26px`"
            />
          </div>

          <!-- Account Info -->
          <div v-if="!loggedIn" class="px-4 flex flex-col">
            <div
              class="mb-5 lg:mb-3 text-center lg:text-left flex flex-col items-center lg:items-start"
            >
              <h3 class="font-semibold text-base lg:text-lg leading-close">Account Info</h3>
              <p class="mt-1 text-sm">
                Enter your email and set a password to create your account.
              </p>
            </div>
            <div class="-mt-10">
              <LoginForm
                v-ref="accountFormRef"
                hide-button
                fields-on-one-line
                @focus="clearErrors"
                @blurPassword="logAddedAccountInfo"
              />
            </div>
            <div class="mt-2">
              Already have an account? log in
              <BaseLinkStyled :to="{ name: 'AccountLogin' }" inline> here </BaseLinkStyled>
            </div>
            <BaseDivider
              color="black"
              :weight="screen.lg ? 1 : 2"
              :class="`${
                screen.lg ? 'w-full' : 'w-screen -ml-8'
              } mt-6 lg:mt-18px mb-10px lg:mb-26px`"
            />
          </div>
        </div>

        <!-- Checkout Breakdown -->
        <div class="lg:max-w-90 lg:ml-4">
          <SelectPlanCard
            v-if="isNewSubscribeDesignEnabled && screen.lg"
            :type="selectedPlanHandle"
            is-for-signup-view
            class=""
          />
          <div
            :class="`lg:border lg:border-black ${
              isNewSubscribeDesignEnabled ? 'rounded-b-md' : 'rounded-md'
            } mb-3 pb-4 flex flex-col`"
          >
            <div class="px-6">
              <div class="font-semibold text-center lg:text-left mb-4 lg:mb-0 lg:mt-15px">
                Order Summary
              </div>
              <template v-if="!isCurrentBoxWaitlist">
                <BasePriceBreakdown class="my-2">
                  <BasePanelWarning v-if="checkoutErrorMessage" class="mb-6">
                    {{ priceBreakdownErrorMessage }}
                  </BasePanelWarning>
                  <BasePriceBreakdownItem
                    :label="`${selectedPlanLabel} Membership`"
                    :price="membershipPrice"
                  />
                  <BasePriceBreakdownItem
                    v-if="
                      subscriptionPreview &&
                      ((priceBreakdown('discount') > 0 && !calculatingDiscount) ||
                        (submittedCouponCodeIsValid && calculatingDiscount))
                    "
                    label="Discount"
                    data-cy="discount"
                    :price="-priceBreakdown('discount')"
                    :calculating="calculatingDiscount"
                  />
                  <BasePriceBreakdownItem
                    v-if="
                      subscriptionPreview &&
                      ((priceBreakdown('credit') > 0 && !calculatingCredit) ||
                        (submittedGiftCardIsValid && calculatingCredit))
                    "
                    label="Credit"
                    data-cy="credit"
                    :price="-priceBreakdown('credit')"
                    :calculating="calculatingCredit"
                  />
                  <BasePriceBreakdownItem
                    label="Shipping"
                    :price="priceBreakdown('shipping')"
                    :calculating="calculatingShipping"
                  />
                  <BasePriceBreakdownItem
                    label="Estimated Tax"
                    :price="priceBreakdown('tax')"
                    :calculating="calculatingTaxAndTotal"
                  />
                  <BaseDivider />
                  <template v-slot:total>
                    <BasePriceBreakdownItem
                      label="Total"
                      data-cy="total-cost"
                      :price="priceBreakdown('total')"
                      :calculating="calculatingTaxAndTotal"
                    />
                  </template>
                  <BaseSpinner
                    v-if="refreshingSubscriptionPreview"
                    overlay="absolute"
                    class="z-10"
                  />
                </BasePriceBreakdown>
              </template>
              <BasePriceBreakdown v-else>
                <BasePanelWarning v-if="checkoutErrorMessage" class="mb-6">
                  {{ priceBreakdownErrorMessage }}
                </BasePanelWarning>
                <BasePriceBreakdownItem label="Waitlist reserve" :price="0" />
                <BaseDivider />
              </BasePriceBreakdown>
              <p class="mt-4 mb-2 text-3xs">
                <template v-if="isCurrentBoxWaitlist">
                  You are joining the Alltrue Waitlist Reserve and agree to be automatically charged
                  using the payment method associated with your account for an Alltrue Seasonal
                  Membership at the full regular price of {{ $formatPrice(PLAN_PRICE.quarterly) }},
                  plus any applicable shipping charges and taxes. To remove yourself from the
                  waitlist, email us at
                  <BaseLinkStyled color="black" href="mailto:help@alltrue.com">
                    help@alltrue.com
                  </BaseLinkStyled>
                  <br /><br />
                  <span v-if="shippingTimeline">
                    {{ shippingTimeline }}
                    <br /><br />
                  </span>
                  Your Membership will renew automatically on a quarterly basis beginning with the
                  next season using the payment method associated with your account. Memberships
                  will auto-renew at the full regular price, plus any applicable shipping charges
                  and taxes. To prevent renewal, you can cancel your Membership online or by
                  emailing us at
                  <BaseLinkStyled color="black" href="mailto:help@alltrue.com">
                    help@alltrue.com </BaseLinkStyled
                  >. All prices listed are in USD. <br /><br />
                  By clicking “Join the waitlist reserve” you are agreeing to the Alltrue
                  <BaseLinkStyled color="black" :to="{ name: 'PageTermsOfUse' }" target="_blank">
                    Terms of Use
                  </BaseLinkStyled>
                  and
                  <BaseLinkStyled color="black" :to="{ name: 'PagePrivacy' }" target="_blank">
                    Privacy Policy
                  </BaseLinkStyled>
                  and acknowledge the above waitlist and renewal terms.
                </template>
                <template v-else-if="!isCurrentBoxWaitlist">
                  <span class="text-gray-opacity68">
                    *Free shipping only applicable in the contiguous US.
                  </span>
                  <br /><br />
                  <span v-if="shippingTimeline">
                    {{ shippingTimeline }}
                    <br /><br />
                  </span>
                  <PrismicRichText
                    v-if="orderSummaryCopy"
                    :field="orderSummaryCopy"
                    :html-serializer="htmlSerializer"
                  />
                  <br />
                  By clicking “Place Order” you are agreeing to the Alltrue
                  <BaseLinkStyled :to="{ name: 'PageTermsOfUse' }" target="_blank" inline>
                    Terms of Use
                  </BaseLinkStyled>
                  and
                  <BaseLinkStyled :to="{ name: 'PagePrivacy' }" target="_blank" inline>
                    Privacy Policy
                  </BaseLinkStyled>
                  and acknowledge the above renewal terms.
                </template>
              </p>
            </div>
          </div>

          <BaseButton
            v-if="!payPalSelected"
            :disabled="checkoutButtonDisabled"
            :color="isCurrentBoxWaitlist ? 'black' : 'primary'"
            @click="startMembership"
          >
            <span v-if="isCurrentBoxWaitlist" class="text-dawn-lt4">Join the waitlist reserve</span>
            <span v-else>Place Order</span>
          </BaseButton>

          <div v-else class="py-2 px-4">
            <PayPalButton :disabled="checkoutButtonDisabled" @click="selectPayPal" />
          </div>
        </div>
      </div>
    </component>

    <Portal to="modal">
      <ExistingMemberModal
        :open="showExistingMemberModal"
        @dismiss="showExistingMemberModal = false"
      />
    </Portal>
  </div>
</template>
