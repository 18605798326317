<script>
  /**
   * PayPal branded button.
   */
  import LogoPayPalButton from '@/images/logos/LogoPayPalButton.svg'

  export default {
    components: {
      LogoPayPalButton,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <div class="w-full flex flex-col items-center">
    <div v-if="disabled" class="z-90 absolute inset-0 bg-white bg-opacity-50" />
    <button
      type="button"
      aria-label="PayPal"
      class="text-center w-full h-9 rounded-full bg-paypal-gold"
      :disabled="disabled"
      v-on="$listeners"
    >
      <div class="flex items-center justify-center">
        <span
          class="mr-1 text-paypal-gray text-xs font-medium"
          style="font-family: Helvetica Neue, sans-serif"
        >
          Pay with
        </span>
        <LogoPayPalButton class="h-5 mt-1" />
      </div>
    </button>
    <span
      class="mt-1 text-paypal-gray text-xs font-medium"
      style="font-family: Helvetica Neue, sans-serif"
    >
      The safer, easier way to pay
    </span>
  </div>
</template>
